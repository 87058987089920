import React from "react";
import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import { GoLinkExternal } from "react-icons/go";

const CompPublications = () => {
    return (
        <>
            <section id="publications" className="bg-light py-5">
                <div className="container">
                    <h2 className="h2 text-primary text-center section-heading">
                        Publications
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />
                    {/* <p className="mb-5 font-22 font-bld text-left">
                        
                    </p> */}
                    <div className="row">
                        <div className="col-md-6 mb-4 mb-md-4  ">
                            <div className="solutionCard bg-white text-center p-3 pb-4">
                                <div className="m-4">
                                    <img
                                        src={"https://www.medrxiv.org/sites/default/files/medRxiv_homepage_logo.png"}
                                        alt="wearable sensors"
                                        className="first"
                                    />
                                </div>
                                <h5 className="font-bold">
                                Multiple Sclerosis in the Digital Health Age: Challenges and Opportunities - A Systematic Review
                                </h5>
                            
                               <a target="_blank" rel="noopener noreferrer"  href="https://www.medrxiv.org/content/10.1101/2023.11.04.23298084v1.article-metrics" className="btn btn-primary ">View Details <GoLinkExternal/></a>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
            </section>
        </>
    );
};

export default CompPublications;
