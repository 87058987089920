import React from 'react';
import Newsletter from '../Home/Newsletter/Newsletter';
import OurSoftwareProducts from '../Home/OurSoftwareProducts/OurSoftwareProducts';
import Solution from '../Home/Solution/Solution';
import ClinicalTrialsResults from './ClinicalTrialsResults';
import ScienceTechHero from './ScienceTechHero';
import TechBodyMirror from './TechBodyMirror';
import TechClinicalDashboard from './TechClinicalDashboard';
import TechCognitiveGames from './TechCognitiveGames';
import TechWearableSensor from './TechWearableSensor';
import CompPublications from './CompPublications';

const ViewScienceTechnology = () => {
    return (
        <>
            <main>
                <ScienceTechHero/>
                <CompPublications/>
                <Solution/>

                <TechWearableSensor/>
                <TechCognitiveGames/>
                <TechBodyMirror/>
                <TechClinicalDashboard/>
                {/* <ClinicalTrialsResults/> */}
                <OurSoftwareProducts/>
                <Newsletter/>
            </main>
        </>
    );
};

export default ViewScienceTechnology;